/*
 * @Autor: hzq
 * @Date: 2022-12-27 17:43:57
 * @LastEditTime: 2022-12-27 17:46:00
 * @Description: 
 * @FilePath: \lyws-inspect-micro-app\src\vueFilter.js
 */
export const initVueFilter = (Vue) => {
  Vue.filter('YY-MM-DD-hh:mm:ss', value => {
    if(!value){
      return '';
    }
    let d = new Date(value);
    let year = d.getFullYear();
    let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : '' + (d.getMonth() + 1) ;
    let day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate();
    let hour = d.getHours();
    let minutes = d.getMinutes();
    let seconds = d.getSeconds();
    if (hour < 10) {hour = "0" + hour}
    if (minutes < 10) {minutes = "0" + minutes}
    if (seconds < 10) {seconds = "0" + seconds}
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
  });
  Vue.filter('YY-MM-DD', value => {
    const d = new Date(value);
    const year = d.getFullYear();
    const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : '' + (d.getMonth() + 1) ;
    const day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate();
    return year + '-' + month + '-' + day;
  });
  Vue.filter('YY-MM', value => {
    const d = new Date(value);
    const year = d.getFullYear();
    const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : '' + (d.getMonth() + 1) ;
    return year + '-' + month;
  });
  Vue.filter('hh:mm', value => {
    const d = new Date(value);
    let hour = d.getHours();
    let minutes = d.getMinutes();
    let seconds = d.getSeconds();
    if (hour < 10) {hour = "0" + hour}
    if (minutes < 10) {minutes = "0" + minutes}
    if (seconds < 10) {seconds = "0" + seconds}
    return hour + ':' + minutes;
  });
  Vue.filter('DD-MM-YY', value => {
    const d = new Date(value);
    const year = d.getFullYear();
    const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : '' + (d.getMonth() + 1) ;
    const day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate();
    return day + '/' + month + '/' + year;
  });
}