/**
  * 判断是否数组
  *
  * @param {Object} obj 对象
  * @return {Boolean}
  */
var objectToString = Object.prototype.toString;
function helperCreateInInObjectString(type) {
    return function(obj) {
        return '[object ' + type + ']' === objectToString.call(obj)
    }
}
export const isArray = Array.isArray || helperCreateInInObjectString('Array');
 
 /**
   * 判断是否对象
   *
   * @param {Object} obj 对象
   * @return {Boolean}
   */
export function isPlainObject(obj) {
    return obj ? obj.constructor === Object : false
}
 
 /**
   * 迭代器
   *
   * @param {Object} obj 对象/数组
   * @param {Function} iterate(item, index, obj) 回调
   * @param {Object} context 上下文
   * @return {Object}
   */
function arrayEach(list, iterate, context) {
    if (list) {
         if (list.forEach) {
             list.forEach(iterate, context)
         } else {
             for (var index = 0, len = list.length; index < len; index++) {
                 iterate.call(context, list[index], index, list)
             }
         }
    }
}
function hasOwnProp(obj, key) {
    return obj && obj.hasOwnProperty ? Object.prototype.hasOwnProperty.call(obj, key) : false
}
function objectEach(obj, iterate, context) {
    if (obj) {
       for (var key in obj) {
         if (hasOwnProp(obj, key)) {
           iterate.call(context, obj[key], key, obj)
         }
       }
    }
}
export function each(obj, iterate, context) {
    if (obj) {
        return (isArray(obj) ? arrayEach : objectEach)(obj, iterate, context)
    }
    return obj
}

function handleMerge(target, source) {
  if ((isPlainObject(target) && isPlainObject(source)) || (isArray(target) && isArray(source))) {
    each(source, function(obj, key) {
      target[key] = handleMerge(target[key], obj)
    })
    return target
  }
  return source
}

/**
  * 将一个或多个源对象合并到目标对象中
  *
  * @param {Object} target 目标对象
  * @param {...Object}
  * @return {Boolean}
  */
 var merge = function(target) {
  if (!target) {
    target = {}
  }
  var args = arguments
  var len = args.length
  for (var source, index = 1; index < len; index++) {
    source = args[index]
    if (source) {
      handleMerge(target, source)
    }
  }
  return target
}

export default merge;