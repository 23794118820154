/*
 * @Autor: hzq
 * @Date: 2022-12-21 17:39:45
 * @LastEditTime: 2022-12-21 17:42:43
 * @Description: 
 * @FilePath: \lyws-base\src\i18n\langs\cn\modelsLangs\login.js
 */
const login = {
    'login': '登录',
    'userName': '用户名',
    'passWord': '密码'
}
export default login