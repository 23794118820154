import Vue from 'vue';
import VueRouter from 'vue-router';

const Home = r => require.ensure([], () => r(require('@/views/home/home.vue')), 'home');
const SignView = r => require.ensure([], () => r(require('@/views/signView.vue')), 'signView');
Vue.use(VueRouter);
let router = new VueRouter({
    // mode: 'history',
    routes: [
        {
          path: '/',
          name: 'home',
          component: Home,
        },
        {
            path: '/sign',
            name: 'sign',
            component: SignView,
          },
        {
            path: '/401',
            component: () => import('@/components/errorPage/401.vue'),
            hidden: true
        },
        {
            path: '/404',
            component: () => import('@/components/errorPage/404.vue'),
            hidden: true
        },
        { path: '*', redirect: '/404' }
    ]
})
export default router;