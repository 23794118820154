/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    sessionStorage.setItem(name, content);
    localStorage.setItem(name, content);
};
/**
 * 获取localStorage
 */
export const getStore = name => {
    if (!name) return;
    return localStorage.getItem(name) || sessionStorage.getItem(name);
};

/**
 * 删除localStorage
 */
export const removeStore = name => {
    if (!name) return;
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
};
export const clearStore = () => {
    localStorage.clear();
    sessionStorage.clear();
}
