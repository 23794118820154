const loadingOptions = {
    lock: true,
    text: "正在努力加载，请稍后",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)"
}
module.exports = {
    excelDownLoadName: '.xlsx',
    docDownLoadName: '.doc',
    zipDownLoadName: '.zip',
    
    
    gatewayUrl: 'https://gateway.realtimegto.com/',
    // gatewayUrl: 'http://183.94.33.147:8186/',
    // gatewayUrl: 'https://www.lyws.online:9443/gateway/',
    bucket: "fppark-pub", //开发bucket/


    loadingOptions: loadingOptions,
    tokenKey: 'lyws-access-token',
    userInfoKey: "access-user",
    version:'3.0.0',
}