/*
 * @Autor: hzq
 * @Date: 2022-12-21 17:39:12
 * @LastEditTime: 2022-12-21 17:42:39
 * @Description: 
 * @FilePath: \lyws-base\src\i18n\langs\en\modelsLangs\login.js
 */
const login = {
    'login': 'login',
    'userName': 'userName',
    'passWord': 'passWord'
}
export default login