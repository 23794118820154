
import axios from 'axios'
import router from '@/router/index.js'
import { setStore, } from "@/utils";

const lywsConfig = require('@config/global');
let pending = []; //声明一个数组用于存储每个请求的取消函数和axios标识
// 定义取消方法
export function cancelRequest(config){
    for(let p in pending){
        if(pending[p].u === config.url + '&' + config.method) { //当当前请求在数组中存在时执行函数体
            pending[p].f(); //执行取消操作
            pending.splice(p, 1); 
        }
    }
}
const createConfig = (url, method, params, options = {}) => {
    const config = { ...options }
    if(options.isCancel) {
        const cb = options.cancelExecutor || function executor(c) {
            pending.push({ u: url + method, f: c });//config.data为请求参数
        }
        config.cancelToken = new axios.CancelToken(cb);
    }
    return config;
}

const instance = axios.create({
    baseURL: '',
    timeout: 6 * 60 * 1000, //设置默认的请求超时时间,
    headers: {
        'device-type': 'WEB',
        'seviceName': 'WEB'
    }
})
instance.defaults.withCredentials = false;

//配置请求头 ,添加一个请求拦截器
instance.interceptors.request.use(function (config) {
    let token = localStorage.getItem(lywsConfig.tokenKey);
    let url = config.url;
    if (url.includes('/loginWeb')) {
        setStore(lywsConfig.tokenKey, '');
        return config;
    }
    else if (!url.includes('/loginWeb')) {
        // eslint-disable-next-line no-useless-escape
        config.headers["token"] = token?.replace(/\"/g, "");
        return config;
    }
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

function handleCodeError(response) {
    if (response.data && response.data.code) {
        if ((parseInt(response.data.code) === 521 || parseInt(response.data.code) === 522 || parseInt(response.data.code) === 401)) {
            localStorage.clear();
            sessionStorage.clear();
            router.replace('/login');
            return true;
        }
    }
    return false;
}
// 添加一个响应拦截器
instance.interceptors.response.use(function (response) {
    if (handleCodeError(response)) return response;
    if (response.data.data && response.data.data.token) {
        setStore(lywsConfig.tokenKey, response.data.data.token);
    }
    return response;
}, function (error) {
    if (error.response) handleCodeError(error.response);
    // Do something with response error
    return Promise.reject(error);
});
//基地址
let base = '';  //接口代理地址参见：config/index.js中的proxyTable配置
const networkError = new Error(!window.navigator.onLine ? '网络异常，请检查网络连接是否正常' : '服务器异常，请联系管理员');
export const POST = (url, params, options) => {
    return new Promise((resolve, reject) => {
        instance.post(`${base}${url}`, params, createConfig(url, 'post', params, options))
        .then(res => {
            resolve(res.data);
        })
        .catch(thrown => {
            if (thrown.message === 'Network Error') {
                reject(networkError);
                return;
            }
            // handle error
            reject(thrown)
        })
    })
}


export const GET = (url, params, options) => {
    return new Promise((resolve, reject) => {
        instance.get(`${base}${url}`, { params: params }, createConfig(url, 'get', params, options))
            .then(res => {
                resolve(res.data);
            })
            .catch(thrown => {
                if (thrown.message === 'Network Error') {
                    reject(networkError);
                    return;
                }
                // handle error
                reject(thrown)
            })
    })
}

export const PUT = (url, params) => {
    return instance.put(`${base}${url}`, params).then(res => res.data)
}

export const DELETE = (url, params) => {
    return instance.delete(`${base}${url}`, { params: params }).then(res => res.data)
}

export const PATCH = (url, params) => {
    return instance.patch(`${base}${url}`, params).then(res => res.data)
}
export default instance;