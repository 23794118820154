/*
 * @Author: zhaosujuan zhaosujuan@freedodata.com
 * @Date: 2023-08-16 16:04:32
 * @LastEditors: zhaosujuan zhaosujuan@freedodata.com
 * @LastEditTime: 2023-08-16 16:29:50
 * @FilePath: \web-group-common-base\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App';
import axios from '@/api';
import store from './store';
import router from './router';
import i18n from '@/i18n/i18n';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/fonts/iconfont.css';
import '@/assets/fonts/iconfont.js';
import vueEsign from 'vue-esign'
import { initVueFilter } from './vueFilter.js';

Vue.use(ElementUI);
Vue.use(vueEsign);
initVueFilter(Vue);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  components: { App },
  template: '<App/>'
});