/**
 * 引用vuex，进行状态管理
 */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
    },
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    }
});