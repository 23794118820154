<!--
 * @Author: zhaosujuan zhaosujuan@freedodata.com
 * @Date: 2023-08-16 16:04:32
 * @LastEditors: zhaosujuan zhaosujuan@freedodata.com
 * @LastEditTime: 2023-12-13 14:25:41
 * @FilePath: \web-group-common-base\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 16px;
  height: 100%;
}
</style>
<style lang="scss">
@import '@/assets/styles/index.scss';
</style>