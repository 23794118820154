/**
 * @description: 类数组转数组
 * @param {*} obj
 * @return {*}
 */
export const toArray = function(obj) {
   return [].slice.call(obj);
};
/**
* @description: 判断是否是函数
* @param {*}
* @return {*}
*/  
export const isFunction = (obj) => {
   return typeof obj === 'function' && typeof obj.nodeType !== 'number';
}
/**
 * @description: 判断是否File对象
 * @param {*} val
 * @return {*}
 */
export const isFile = (val) => Object.prototype.toString.call(val) === '[object File]';