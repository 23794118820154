import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'
import Elementlocale from 'element-ui/lib/locale'

Vue.use(VueI18n)
let languages = navigator.language || navigator.userLanguage;
languages = 'zh-cn'; // 先暂时默认中文，不跟随浏览器语言环境

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || languages || 'zh-cn',
  messages
})
Elementlocale.i18n((key, value) => i18n.t(key, value))

export const getInitI18nState = () => {
  const locale = i18n.locale;
  return {
    locale,
    i18nMessages: i18n.getLocaleMessage(locale) 
  }
}
export default i18n